import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "@components/layout";
import Seo from "@components/seo";
import { Helmet } from "react-helmet";
import TopNav from "@components/topNav";
import Footer from "@components/footer";

import addToMailchimp from "gatsby-plugin-mailchimp";

import {
  AcademicCapIcon,
  BadgeCheckIcon,
  CashIcon,
  ClockIcon,
  ReceiptRefundIcon,
  UsersIcon,
  SaveAsIcon,
} from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Newsletter = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [organisation, setOrganisation] = useState("");
  //   const [phone, setPhone] = useState(null);

  const [errorMessage, setErrorMessage] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  // console.log(email);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      firstName.length &&
      lastName.length &&
      email.length > 0
      // organisation.length > 0
    ) {
      addToMailchimp(email, {
        FNAME: firstName,
        LNAME: lastName,
        MMERGE5: organisation,
      }) // listFields are optional if you are only capturing the email address.
        .then((data) => {
          // I recommend setting data to React state
          // but you can do whatever you want (including ignoring this `then()` altogether)
          // console.log(data);
          setMessageSent(true);
        })
        .catch((error) => {
          // console.log(error);
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        });

      //   const res = await fetch("/api/mail", {
      //     body: JSON.stringify({
      //       email: email,
      //       //   fullname: fullname,
      //       //   phone: phone,
      //       //   message: message,
      //     }),
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     method: "POST",
      //   });

      //   const { error } = await res.json().then(setMessageSent(true));
      //   if (error) {
      //     console.log(error);
      //     return;
      //   }

      // console.log(firstName, lastName, organisation, email);
    } else {
      setErrorMessage(true);
    }
  };

  return (
    <div>
      <Helmet title={"Avert newsletter | Avert"}></Helmet>
      <div className="relative bg-orange overflow-hidden">
        <div className="max-w-6xl mx-auto">
          <div className="relative z-10 pb-8 bg-orange sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden md:block pl-20 lg:block absolute right-0 inset-y-0 h-full w-56 text-lime transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 180 585"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="Path"
                  fill="#FF8500"
                  fillRule="nonzero"
                  points="0.296038107 585 58.6111495 585 179.266137 296.235761 60.0246807 0 0.296038107 0"
                ></polygon>
              </g>
            </svg>

            <TopNav theme={"sunset"} />

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left pb-16">
                {" "}
                {/* Padding botom if no video button */}
                {/* <h1 className="text-4xl tracking-tight font-bold font-heading text-marineDark sm:text-5xl md:text-6xl"> */}
                <h1 className="text-4xl tracking-loose font-bold text-fossil sm:text-5xl md:text-5xl font-heading">
                  Sign up to our emails
                  {/* <span className="block text-indigo-600 xl:inline"></span> */}
                </h1>
                <div className="pt-12">
                  <p className="mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Keep up-to-date with all the latest updates on our work by
                    signing up to the Avert newsletter.
                  </p>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <StaticImage
            src="../images/email-sign-up.jpg"
            className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Avert"
            placeholder="blurred"
            layout="fullWidth"
            // width={2000}
            // height={1125}
          />
        </div>
      </div>
      {/* <div className="relative bg-gray-50 pt-10 pb-6 px-4 sm:px-6 lg:pt-10 lg:pb-6 lg:px-8">
        <div className="relative max-w-7xl mx-auto"></div>
      </div> */}

      <div className="">
        <div className="relative py-16 bg-white overflow-hidden">
          <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full"></div>
          <div className="relative px-4 sm:px-6 lg:px-8">
            <div className="text-lg max-w-prose mx-auto">
              {!messageSent ? (
                // <p className="mt-0 text-xl text-fossil leading-8">
                //   Fill in your details below and you’ll receive regular emails
                //   with information of what we’ve been getting up to at Avert.
                // </p>

                <div className="text-lg max-w-prose mx-auto">
                  <span className="mt-0 prose prose-lg text-fossil mx-auto">
                    Fill in your details below and you’ll receive regular emails
                    with information of what we’ve been getting up to at Avert.
                  </span>
                </div>
              ) : null}
            </div>
            {!messageSent ? (
              <div className="relative max-w-2xl mx-auto pt-8">
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-1 gap-y-6"
                >
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      className={
                        errorMessage && !email && !email.length > 0
                          ? "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-2 border-red-500 rounded-md"
                          : "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled rounded-md"
                      }
                      // className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled rounded-md"
                      placeholder="Email *"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      First name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className={
                        errorMessage && !firstName && !firstName.length > 0
                          ? "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-2 border-red-500 rounded-md"
                          : "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled rounded-md"
                      }
                      placeholder="First name *"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className={
                        errorMessage && !lastName && !lastName.length > 0
                          ? "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-2 border-red-500 rounded-md"
                          : "block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled rounded-md"
                      }
                      placeholder="Last name *"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      Organisation
                    </label>
                    <input
                      type="text"
                      name="organisation"
                      id="organisation"
                      className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled rounded-md"
                      placeholder="Organisation"
                      onChange={(e) => {
                        setOrganisation(e.target.value);
                      }}
                    />
                  </div>

                  {/* <div>
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      className="block w-full shadow-md py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-disabled  rounded-md"
                      placeholder="Phone"
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                    />
                  </div> */}

                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-disabled hover:bg-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>

                {errorMessage && (
                  <p className="mt-3">Please complete all required fields</p>
                )}
                <div className="text-lg max-w-prose mx-auto">
                  {/* <p className="mt-6 text-lg text-fossil leading-8"> */}
                  <p className="mt-6 prose prose-lg text-fossil mx-auto">
                    You can update your preferences or unsubscribe from the
                    Avert newsletter at any time by clicking the link in the
                    footer of our emails. For information about our privacy
                    practices, please read our{" "}
                    <Link to="/privacy-policy">privacy policy</Link>.
                  </p>
                  <p className="mt-6 prose prose-lg text-fossil italic mx-auto">
                    Photo credit: Gulshan Khan/Avert/Ikusasa Lethu. Photos are
                    used for illustrative purposes. They do not imply health
                    status or behaviour.
                  </p>
                </div>
              </div>
            ) : (
              <div className="ml-3 py-8 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
                <div className="text-lg max-w-prose mx-auto">
                  <span className="mt-0 prose prose-lg text-fossil mx-auto">
                    Thank you for subscribing!
                  </span>
                </div>
                {/* <div className="max-w-lg mx-auto lg:max-w-none">
                  <p className="mt-3 text-2xl">Thank you for subscribing!</p>
       
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Newsletter;
